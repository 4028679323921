// selectors.js
export const selectAuthState = (state) => state.auth;

export const selectIsLoggedIn = (state) => selectAuthState(state).login;

export const selectAuthLoading = (state) => selectAuthState(state).loading;

export const selectAuthError = (state) => selectAuthState(state).error;

export const selectMyContext = (state) => selectAuthState(state).context;

export const selectMySchools = (state) => selectAuthState(state).context.schools;
