import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddProfile = lazy(() => import('../../../container/school/profile/AddProfile'));
const EditProfile = lazy(() => import('../../../container/school/profile/EditProfile'));
const List = lazy(() => import('../../../container/school/profile/List'));
const NotFound = lazy(() => import('../../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="add-profile/*" element={<AddProfile />} />
      <Route path=":profileId/:operation/*" element={<EditProfile />} />
      <Route path="list" element={<List />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
