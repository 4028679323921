import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { UilEnvelope, UilUsersAlt, UilBookOpen, UilBullseye } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import { TopMenuStyle } from './Style';
import { selectMySchools } from '../redux/authentication/selectors';

function TopMenu() {
  const { t } = useTranslation();
  const path = '/admin';

  const schools = useSelector(selectMySchools);

  useLayoutEffect(() => {
    const active = document.querySelector('.hexadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

  const getSchools = () => {
    return schools.map((s) => {
      return (
        <li className="has-subMenu-left">
          <Link to="#" className="parent">
            <UilEnvelope />
            {s.name}
          </Link>
          <ul className="subMenu">
            <li className="has-subMenu-left">
              <Link to="#" className="parent">
                <UilUsersAlt />
                {t('members')}
              </Link>
              <ul className="subMenu">
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/profiles/list`}>
                    {t('list')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/profiles/add-profile/edit/personal`}>
                    {t('add')} {t('new')} {t('member')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/profiles/import`}>
                    {t('import')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/profiles/export`}>
                    {t('export')}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="has-subMenu-left">
              <Link to="#" className="parent">
                <UilBookOpen />
                {t('section')}
              </Link>
              <ul className="subMenu">
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/sections/list`}>
                    {t('list')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/sections/add-section/edit/info`}>
                    {t('add')} {t('new')} {t('section')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/sections/import`}>
                    {t('import')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/sections/export`}>
                    {t('export')}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="has-subMenu-left">
              <Link to="#" className="parent">
                <UilBullseye />
                {t('features')}
              </Link>
              <ul className="subMenu">
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/app/note/all`}>
                    {t('note')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/app/to-do`}>
                    {t('to')} {t('do')}
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={addParentActive} to={`${path}/${s._id}/app/task/all`}>
                    {t('task')}
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      );
    });
  };

  return (
    <TopMenuStyle>
      <div className="hexadash-top-menu ltr:pl-[20px] rtl:pr-[20px] xl:ltr:pl-[10px] xl:rtl:pr-[10px]">
        <ul>
          <li className="">
            <Link to={`${path}`} className="parent">
              {t('dashboard')}
            </Link>
          </li>
          <li className="">
            <Link to={`${path}/account/my/overview`} className="parent">
              {t('my')} {t('profile')}
            </Link>
          </li>

          <li className="has-subMenu">
            <Link to="#" className="parent">
              {t('schools')}
            </Link>
            <ul className="subMenu">{getSchools()}</ul>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
