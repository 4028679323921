import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddSection = lazy(() => import('../../../container/school/section/AddSection'));
const List = lazy(() => import('../../../container/school/section/List'));
const NotFound = lazy(() => import('../../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path=":sectionId/:operation/*" element={<AddSection />} />
      <Route path="list" element={<List />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
