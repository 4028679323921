import { UilCreateDashboard, UilBuilding, UilUsersAlt, UilBookOpen, UilBullseye } from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import { selectMySchools } from '../redux/authentication/selectors';

function SideMenu({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const schools = useSelector(selectMySchools);

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const schoolItems = () => {
    return schools.map((s, index) =>
      getItem(s.name, `${index}`, !topMenu && <UilBuilding />, [
        getItem(
          <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
            {t('members')}
          </NavTitle>,
          'members',
          <UilUsersAlt />,
          [
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/profiles/list`}>
                {t('list')}
              </NavLink>,
              'user-list',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/profiles/add-profile/personal`}>
                {t('add')} {t('new')} {t('member')}
              </NavLink>,
              'user-add',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/profiles/import`}>
                {t('import')}
              </NavLink>,
              'user-import',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/profiles/export`}>
                {t('export')}
              </NavLink>,
              'user-export',
              null,
            ),
          ],
          'group',
        ),
        getItem(
          <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
            Classes
          </NavTitle>,
          'classes',
          <UilBookOpen />,
          [
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/sections/list`}>
                {t('list')}
              </NavLink>,
              'section-list',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/sections/add-section/edit/info`}>
                {t('add')} {t('new')} {t('section')}
              </NavLink>,
              'section-add',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/sections/import`}>
                {t('import')}
              </NavLink>,
              'section-import',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/sections/export`}>
                {t('export')}
              </NavLink>,
              'section-export',
              null,
            ),
          ],
          'group',
        ),
        getItem(
          <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
            {t('features')}
          </NavTitle>,
          'features',
          <UilBullseye />,
          [
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/app/note/all`}>
                {t('note')}
              </NavLink>,
              'note',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/app/to-do`}>
                {t('to')} {t('do')}
              </NavLink>,
              'to-do',
              null,
            ),
            getItem(
              <NavLink onClick={toggleCollapsed} to={`${path}/${s._id}/app/task/all`}>
                {t('task')}
              </NavLink>,
              'task',
              null,
            ),
          ],
          'group',
        ),
      ]),
    );
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/account/my/overview`}>
        {t('my')} {t('profile')}
      </NavLink>,
      'myProfile',
      !topMenu && <UilUsersAlt />,
    ),
    getItem(
      !topMenu && (
        <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
          {t('schools')}
        </NavTitle>
      ),
      'app-title',
      null,
      null,
      'group',
    ),
    ...schoolItems(),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

SideMenu.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default SideMenu;
