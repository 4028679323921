import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import Profiles from './schools/profiles';
import Sections from './schools/sections';
import withAdminLayout from '../../layout/withAdminLayout';

const Import = lazy(() => import('../../container/importExport/Import'));
const Export = lazy(() => import('../../container/importExport/Export'));
const MyAccount = lazy(() => import('../../container/account/myProfile'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path=":schoolId/profiles/*" element={<Profiles />} />
        <Route path=":schoolId/sections/*" element={<Sections />} />
        <Route path=":schoolId/:path/import" element={<Import />} />
        <Route path=":schoolId/:path/export" element={<Export />} />
        <Route path="account/my/*" element={<MyAccount />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
