import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { removeItem, setItem } from '../../utility/localStorageControl';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, myContext } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/accounts/login', values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        setItem('access_token', response.data.token);
        const contextRes = await DataService.get('/context/my');
        if (contextRes.data.errors) {
          dispatch(loginErr(response.data.errors));
        } else {
          const context = contextRes.data;
          setItem('logedIn', true);
          dispatch(myContext(context));
          setItem('context', context);
          dispatch(loginSuccess(true));
          callback();
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/accounts', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        setItem('access_token', response.data.token);
        const contextRes = await DataService.get('/context/my');
        if (contextRes.data.errors) {
          dispatch(loginErr(response.data.errors));
        } else {
          const context = contextRes.data;
          setItem('logedIn', true);
          dispatch(myContext(context));
          setItem('context', context);
          dispatch(loginSuccess(true));
          callback();
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      removeItem('logedIn');
      removeItem('access_token');
      removeItem('account');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
